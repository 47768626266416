<div class="grid">

  <div class="col-12">
    <div class="card shadow-2 m-3 p-3 bg-white">
      <p-table #dt [value]="services" [paginator]="false" [rows]="10" [showCurrentPageReport]="false"
        [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-gridlines p-datatable-sm"
        [tableStyle]="{ 'min-width': '75rem' }" [rowHover]="true" dataKey="id" selectionMode="single"
        loadingIcon="pi pi-cog pi-spin text-white">

        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between">
            <h2>Customers</h2>
            <ng-container>
              <div style="width: 50%;">
                <input id="password" type="text" placeholder="Search customer" pInputText class="w-full"
                  [(ngModel)]="filterService.search" />
              </div>
            </ng-container>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr class="px-2">
            <th style="width: 4rem" class="text-center">#</th>
            <th pSortableColumn="khhdon" class="text-center" style="min-width: 7rem">
              Name
            </th>
            <th pSortableColumn="shdon" class="text-center" style="min-width: 7rem">
              Phone
            </th>
            <th pSortableColumn="tdlap" class="text-center" style="min-width: 7rem">
              Email
            </th>
            <th pSortableColumn="tdlap" class="text-center" style="min-width: 7rem">
              Booking time
            </th>
            <th class="text-center">
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-service>
          <tr style="height: 4rem;">
            <td class="text-center">
              <p-button icon="pi pi-bars" text="true" class="p-button-sm" />
            </td>
            <td>
              {{ service.name }}
            </td>
            <td class="text-center">
              {{ service.phone }}
            </td>
            <td>
              {{ service.email }}
            </td>
            <td class="text-center">
              {{ service.bookingDate | formatDate}}
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="flex align-items-center justify-content-center sticky bottom-0 bg-white w-full z-1">
        <div>
          <p-button icon="pi pi-sync" type="primary" [text]="true" (onClick)="GetService()" />
        </div>
        <div class="flex-1">
          <p-paginator [rows]="filterService.pageSize" [totalRecords]="totalRecord"
            [rowsPerPageOptions]="[10, 20, 30, 100]" [showCurrentPageReport]="true"
            (onPageChange)="OnChangeServicePage($event)" />
        </div>
      </div>
    </div>

  </div>
</div>