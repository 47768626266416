<div class="card flex shadow-3 justify-content-center p-2 border-round-xl layout-sidebar">
  <!-- <p-panelMenu [model]="items" class="w-full">
      <ng-template pTemplate="item" let-item>
          <ng-container *ngIf="item.route; else urlRef">
              <a [routerLink]="item.route" class="flex align-items-center
                  cursor-pointer text-color px-3 py-2">
                  <span [class]="item.icon"></span>
                  <span class="ml-2 text-color">{{ item.label }}</span>
              </a>
          </ng-container>
          <ng-template #urlRef>
              <a *ngIf="item.url; else noLink" [href]="item.url" class="flex align-items-center
                  cursor-pointer text-color px-3 py-2">
                  <span [class]="item.icon"></span>
                  <span class="ml-2">{{ item.label }}</span>
              </a>
              <ng-template #noLink>
                  <span class="flex align-items-center cursor-pointer text-color px-3 py-2">
                      <span [class]="item.icon"></span>
                      <span class="ml-2">{{ item.label }}</span>
                      <i *ngIf="item.items" class="pi pi-angle-down text-primary ml-auto"></i>
                  </span>
              </ng-template>
          </ng-template>
      </ng-template>
  </p-panelMenu> -->

  <p-panelMenu [model]="items" class="w-full" [multiple]="true" />
</div>
