<div class="layout-wrapper surface-ground">
  <app-top-bar></app-top-bar>
  <div class="grid layout-main-container">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
    <div class="col-12 py-0">
      <app-footer></app-footer>
    </div>
  </div>
</div>
