<p-dialog
  [header]="modalOptions?.title"
  (onHide)="close()"
  [(visible)]="visible"
  [modal]="true"
  [breakpoints]="{ '575px': '90vw' }"
  [style]="{ width: modalOptions?.size }"
  [draggable]="true"
  [resizable]="false"
>
  <ng-template #modalBody></ng-template>
  <ng-container *ngIf="modalOptions?.showFooter">
    <div class="flex justify-content-end gap-2 px-2 pt-3">
      <p-button icon="pi pi-check" label="{{ modalOptions?.primaryButtonText || 'Lưu thay đổi' }}" (onClick)="handlePrimaryAction()" />
      <p-button icon="pi pi-times" label="{{ modalOptions?.secondaryButtonText || 'Đóng' }}" severity="secondary" (onClick)="handleSecondaryAction()" />
    </div>
  </ng-container>
  <ng-container>
    <div class="flex justify-content-end gap-2 px-2 pt-3">

    </div>
  </ng-container>
</p-dialog>
