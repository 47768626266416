<div class="grid">

  <div class="col-12">
    <div class="card shadow-2 m-3 p-3 bg-white">
      <p-table #dt [value]="employees" [paginator]="false" [rows]="10" [showCurrentPageReport]="false"
        [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-gridlines p-datatable-sm"
        [tableStyle]="{ 'min-width': '75rem' }" [rowHover]="true" dataKey="id" selectionMode="single"
        [loading]="isLoading"
        loadingIcon="pi pi-cog pi-spin text-white">

        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between">
            <h2>Staff</h2>
            <div>

            </div>
            <ng-container>
              <div>

                &nbsp;
                <p-button severity="info" label="Create employee" icon="pi pi-plus" class="mr-2" (onClick)="Detail()" />
              </div>
            </ng-container>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr class="px-2">
            <th style="width: 4rem" class="text-center">#</th>
            <th class="text-center" style="min-width: 7rem">
              Name
            </th>
            <th class="text-center">
              Avatar
            </th>
            <th class="text-center" style="min-width: 7rem">
              Position
            </th>
            <th class="text-center" style="min-width: 8rem">
              UserName
            </th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-employee>
          <tr style="height: 4rem;">
            <td class="text-center">
              <p-button icon="pi pi-bars" text="true" class="p-button-sm" (onClick)="Detail(employee)" />
            </td>
            <td>
              {{ employee.name }}
            </td>
            <td>
              <p-avatar [image]="employee.avatar" styleClass="mr-2"
                size="xlarge" shape="circle" />
            </td>
            <td>
              {{ employee.position }}
            </td>
            <td>
              {{ employee.userName }}
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="flex align-items-center justify-content-center sticky bottom-0 bg-white w-full z-1">
        <div>
          <p-button icon="pi pi-sync" type="primary" [text]="true" (onClick)="GetEmployee()" />
        </div>
        <div class="flex-1">
          <p-paginator [rows]="filter.pageSize" [totalRecords]="totalRecord" [rowsPerPageOptions]="[10, 20, 30, 100]"
            [showCurrentPageReport]="true" (onPageChange)="OnChangeServicePage($event)" />
        </div>
      </div>
    </div>

  </div>
</div>