<div class="grid">
  <div class="col-5">
    <div class="card shadow-2 m-3 p-3 bg-white">
      <p-table #dt [value]="groups" [rows]="10" [paginator]="false" styleClass="p-datatable-gridlines p-datatable-sm"
        [tableStyle]="{ 'min-width': '50rem' }" [(selection)]="selectedAccount" [rowHover]="true" dataKey="id"
        selectionMode="single" [rowsPerPageOptions]="[10, 20, 30]" [loading]="isGroupLoading"
        loadingIcon="pi pi-cog pi-spin text-white">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between">
            <h2>Service Group</h2>
            <ng-container>
              <div>
                <p-button severity="info" label="Create" icon="pi pi-plus" class="mr-2"
                  (onClick)="OpenCategoryGroup()" />
              </div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr class="px-2">
            <th style="width: 4rem; text-align: center;">#</th>
            <th pSortableColumn="taxCode" style="min-width: 6rem">
              Name <p-sortIcon field="name" />
            </th>
            <th pSortableColumn="name">
              Description <p-sortIcon field="description" />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-group>
          <tr [pSelectableRow]="selectedAccount" style="height: 4rem;">
            <td class="text-center">
              <p-button icon="pi pi-bars" class="p-button-sm" text="true" (onClick)="OpenCategoryGroup(group)" />
            </td>
            <td>
              {{ group.name }}
            </td>
            <td>
              {{ group.description }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="col-7">
    <div class="card shadow-2 m-3 p-3 bg-white">
      <p-table #dt [value]="services" [paginator]="false" [rows]="10" [showCurrentPageReport]="false"
        [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-gridlines p-datatable-sm"
        [tableStyle]="{ 'min-width': '75rem' }" [rowHover]="true" dataKey="id" selectionMode="single"
        loadingIcon="pi pi-cog pi-spin text-white">

        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between">
            <h2>Services</h2>
            <div>

            </div>
            <ng-container>
              <div style="width: 50%;">
                <p-dropdown [options]="groups" optionLabel="name" optionValue="id"
                [(ngModel)]="filterService.categoryId" [showClear]="true" (ngModelChange)="OnChangeCategory($event)"
                placeholder="Select group" />
              </div>
              <div>
                
                &nbsp;
                <p-button severity="info" label="Create" icon="pi pi-plus" class="mr-2"
                  (onClick)="OpenService()" />
              </div>
            </ng-container>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr class="px-2">
            <th style="width: 4rem" class="text-center">#</th>
            <th pSortableColumn="khhdon" class="text-center" style="min-width: 7rem">
              Name
            </th>
            <th pSortableColumn="shdon" class="text-center" style="min-width: 7rem">
              Group
            </th>
            <th pSortableColumn="tdlap" class="text-center" style="min-width: 8rem">
              Price
            </th>
            <th class="text-center">
              Duration (m)
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-service>
          <tr style="height: 4rem;">
            <td class="text-center">
              <p-button icon="pi pi-bars" text="true" class="p-button-sm" (onClick)="OpenService(service)"/>
            </td>
            <td>
              {{ service.name }}
            </td>
            <td>
              {{ service.categoryName }}
            </td>
            <td class="text-center">
              {{ service.price }}
            </td>
            <td class="text-center">
              {{ service.duration }}
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="flex align-items-center justify-content-center sticky bottom-0 bg-white w-full z-1">
        <div>
          <p-button icon="pi pi-sync" type="primary" [text]="true" (onClick)="GetService()" />
        </div>
        <div class="flex-1">
          <p-paginator [rows]="filterService.pageSize" [totalRecords]="totalRecord"
            [rowsPerPageOptions]="[10, 20, 30, 100]" [showCurrentPageReport]="true"
            (onPageChange)="OnChangeServicePage($event)" />
        </div>
      </div>
    </div>

  </div>
</div>